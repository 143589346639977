/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h4: "h4",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Geschäft für Kunst und Handwerk"), "\n", React.createElement(_components.h4, null, "Fürholzer Patrick"), "\n", React.createElement(_components.p, null, "Sie suchen ein besonderes Geschenk für einen Anlass wie Geburtstag, Hochzeit, Taufe oder Jubiläum? Sie wollen für ihr Unternehmen Werbemittel anfertigen die beeindrucken?\nGerne fertige ich für Sie eine individuell designte Einzelanfertigung aus den unterschiedlichsten, nachhaltigen Materialen wie zum Beispiel Holz, Stein, Edelstahl, Glas, Kork oder wiederverwertbarem Kunststoff.\nWir haben für Sie auf jeden Fall das passende Unikat und freuen uns auf eine zukünftige Zusammenarbeit."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
