/** @jsx jsx */
import { Box, jsx, Container, Flex, Link } from "theme-ui"
import SocialMediaList from "./social-media-list"
import AboutMeMDX from "../texts/about-me.mdx"

const Footer = () => {
  return (
    <Box
      as="footer"
      variant="layout.footer"
      sx={{
        background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%)`,
      }}
    >
      <Container>
        <div sx={{ display: `grid`, gridGap: 4, gridTemplateColumns: [`1fr`, `1fr`, `1fr`, `2fr 1fr`] }}>
          <div
            sx={{
              p: { mb: 0 },
              h2: {
                mt: 0,
                mb: 1,
              },
            }}
          >
            <AboutMeMDX />
          </div>
          <Flex
            sx={{
              textAlign: [`center`, `center`, `center`, `right`],
              flexDirection: `column`,
              justifyContent: `flex-end`,
            }}
          >
            <div sx={{ mt: [4, 4, 4, 0] }}>
              <div sx={{ a: { ml: [1, 1, 1, 2], mr: [1, 1, 1, 0] } }}>
                <SocialMediaList />
              </div>
              <div sx={{ color: `textMuted` }}>Copyright &copy; {new Date().getFullYear()}. All rights reserved. <br/><small>Made with <Link href="https://fjoerg.com/" target="_blank" rel="noopener">&#9829; fjoerg.com</Link></small></div>
            </div>
          </Flex>
        </div>
      </Container>
      <Container
        sx={{
          display: `flex`,
          justifyContent: `center`,
          alignItems: `center`,
          color: `text`,
          fontWeight: `semibold`,
          a: { color: `text` },
          mt: 4,
        }}
      >
        <Link
          aria-label="Link zum Impressum"
          sx={{ ml: 2 }}
          href="/impressum"
        >
          Impressum
        </Link>
      </Container>
    </Box>
  )
}

export default Footer
